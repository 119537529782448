<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'rec-chat-leave-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { leaveRoom } = useChat()

    return { leaveRoom }
  },
  data() {
    return {
      error: null,
      processing: false,
    }
  },
  methods: {
    async leave(ownerId) {
      try {
        this.processing = true

        await this.leaveRoom({ room: this.room, ownerId })
        this.$emit('success')
        this.onClose()
      } catch (error) {
        this.error = get(error, 'response.data.message', 'Network error')
        console.error(error)
      } finally {
        this.processing = false
      }
    },
    async onLeave() {
      const { room } = this

      if (room.isMeOwner) {
        modal.show('rec-members-manager', {
          props: {
            title: 'Select new Admin',
            selectable: true,
            source: this.room,
          },
          on: {
            save: (user) => {
              this.leave(user.id)
            },
          },
        })
      } else {
        this.leave()
      }
    },
    onClose() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" :closable="!processing" width="xse">
    <div class="relative p-5 sm:p-[30px]">
      <div class="absolute top-0 right-0">
        <base-button
          icon="plain/close"
          class="m-5 sm:m-[30px]"
          @click="onClose"
        />
      </div>

      <div class="mt-[30px] mb-6 text-center">
        <base-icon
          :svg="error ? 'rec/alert' : 'rec/leave'"
          :size="64"
          class="mx-auto text-fg-error"
        />
      </div>
      <div class="text-center">
        <template v-if="error">
          <h2 class="leading-tight">Error while leaving Chat Room</h2>
          <p class="mt-[30px]">
            {{ error }}
          </p>
          <p>Please, try again later.</p>
          <base-button class="mt-10" full-width @click="onClose">
            Close
          </base-button>
        </template>
        <template v-else>
          <h2 class="leading-tight">
            Are you sure you wish to leave this Chat?
          </h2>
          <base-button
            class="mt-10"
            full-width
            :loading="processing"
            @click="onLeave"
          >
            Leave
          </base-button>
        </template>
      </div>
    </div>
  </base-mdl>
</template>
